export type IUser = {
  id: string
  username: string
  displayName: string
  email: string
  bio: string
  permission: UserPermissionEnum
  gender: GenderEnum
  avatarUrl: string | null
  coverUrl: string | null
  facebookContact: string | null
  instagramContact: string | null
  location?: string | null
  showEmail: boolean
  showProfile: boolean
  tel: string | null
  lastActiveAt: Date
  createdAt: Date
  updatedAt: Date
  hasNoPassword: boolean
  hasNoUsername: boolean
}
// public information of IUser
export type IProfile = {
  id: string
  username: string
  displayName: string
  avatarUrl: string | null
  coverUrl: string | null
  facebookContact: string | null
  instagramContact: string | null
  bio: string
  gender: GenderEnum
  lastActiveAt: Date
}

export enum GenderEnum {
  Unknown,
  Male,
  Female,
  Another,
}

export enum UserPermissionEnum {
  // ANONYMOUS = 0,
  USER = 2,
  ADMINISTRATOR = 4,
  TAG_EDIT = 8,
  TAG_HIDDEN = 16,
  NEWS_CREATE_JOURNALIST_GROUP = 32,
  NEWS_DELETE_JOURNALIST_GROUP = 64,
  NEWS_CATEGORY_CREATE = 128,
  NEWS_CATEGORY_EDIT = 256,
  NEWS_CATEGORY_DELETE = 512,
  USER_SEARCH = 1024,
  USER_READ = 2048,
  NEWS_COUNTRY_CREATE = 4096,
  NEWS_COUNTRY_EDIT = 8192,
  NEWS_COUNTRY_DELETE = 16384,
}

export enum PaginationSortEnum {
  DESC,
  ASC,
}

export type PaginationQuery = {
  page?: number
  limit?: number
  sort?: PaginationSortEnum
}

export type ITag = {
  id: number
  parentId: number | null
  name: string
  slug: string
  description: string | null
  metaTitle: string | null
  metaDescription: string | null
  hidden: boolean
  children: ITag[]
  createdAt: Date
  updatedAt: Date
}

export enum NewsFeelingEnum {
  NONE = 0,
  HAPPY = 1,
  WOW = 2,
  LOVE = 3,
  ANGRY = 4,
  CRYING = 5,
  OMG = 6,
}

export enum NewsStatusEnum {
  DRAFT,
  PUBLISHED,
  HIDDEN,
}

export type INewsCard = {
  id: number
  title: string
  markdown: string
  slug: string
  coverImageUrl: string
  squareImageUrl: string
  shortUrl: string
  publishedAt: Date
  totalView: number
  author: IProfile | null
  category: INewsCategory | null
}
// show on journalist bo
export type INewsListItem = {
  id: number
  slug: string
  title: string
  coverImageUrl: string
  author: IProfile | null
  status: NewsStatusEnum
  statusText: string
  approvalText: string
  publishedAt: Date
  createdAt: Date
  updatedAt: Date
  isApprovedLifeTime: boolean
  isApprovedPermanent: boolean
}

// query from news by slug
export interface INewsDetail extends INewsCard {
  shortSlug: string
  uuid: string
  categoryId: string
  authorId: string
  html: string
  countryCode: number
  totalKiss: number
  totalVote: number
  totalLove: number
  metaTitle: string | null
  metaDescription: string | null
  videoUrl: string | null
  tiktokUrl: string | null
  createdAt: Date
  comments: INewsCommentCard[] | null
  feelingsStat: INewsFeelingsStat
  tags: ITag[] | null
  albums: INewsAlbumItem[] | null
  references: INewsReferences[] | []
}

export type INewsFeelingsStat = {
  totalHappy: number
  totalWow: number
  totalOmg: number
  totalLove: number
  totalAngry: number
  totalCrying: number
}

export type INewsCommentCard = {
  id: number
  no: number
  comment: string
  user: IProfile | null
  replies?: INewsCommentCard[]
  createdAt: Date
  newsSlug: string
}

export type INewsCountry = {
  id: number
  label: string
  code: string
}

export type INewsJournalistForm = {}

export type INewsAlbumItem = {
  id: number
  imageUrl: string
  caption: string | null
  createdAt: Date
  updatedAt: Date
}

export type INewsCategory = {
  id: string
  name: string
  sortOrder: number
  createdAt: Date
  updatedAt: Date
}

export type INewsUserInteraction = {
  id: number | null
  userId: string | null
  newsId: number | null
  hasLove: boolean
  hasKiss: boolean
  hasVote: boolean
  hasFavorite: boolean
  feeling: NewsFeelingEnum
  createdAt: Date | null
  updatedAt: Date | null
}

export enum NewsJournalistClassEnum {
  // can approve permanent
  Head = 1,
  // can approve permanent
  Audit,
  // can approve temporally to Freelance and Trainee
  Leader,
  // can approve own news temporally
  Professional,

  Freelance,
  Trainee,

  // deleted status can't do anything
  Deleted,
}

export type IJournalistGroupStatistics = {
  totalViews: number
  totalNews: number
  totalMembers: number
  totalNewsInThisWeek: number
  totalCategory: number
}

export type IJournalistGroupItem = {
  id: string
  name: string
  owner: IProfile | null
  members: IJournalistMemberItem[] | null
  me: IJournalistMemberItem | null
  createdAt: Date
  updatedAt: Date
}

export type IJournalistMemberItem = {
  profile: IProfile | null
  levelClassMsg: string
  level: NewsJournalistClassEnum
  InvitedByUserId: string
  InviteBy: IProfile | null
  createdAt: Date
  updatedAt: Date
}
export type INewsReferences = {
  label: string
  link: string
}
export type INewsDetailForm = {
  id: string
  fullUrl: string
  shortUrl: string
  coverImageUrl: string | File
  squareImageUrl: string | File
  videoUrl: string | null
  tiktokUrl: string | null
  title: string
  metaTitle: string | null
  metaDescription: string | null
  markdown: string
  categoryId: string
  countryCode: number
  publishedDate: Date
  html: string
  albums: INewsAlbumItem[] | null
  tags: ITag[] | null
  status: NewsStatusEnum
  isApprovedLifetime: boolean
  isApprovedPermanent: boolean
  references: INewsReferences[] | null
}

export type INewsDetailCreateForm = {
  coverImageUrl: File
  squareImageUrl: File
  videoUrl: string
  tiktokUrl: string
  title: string
  metaTitle: string
  metaDescription: string
  markdown: string
  categoryId: string
  countryCode: number
  publishedDate: string
  html: string
  albums: INewsAlbumItem[]
  tags: string
  status: NewsStatusEnum
  isApprovedLifetime: boolean
  isApprovedPermanent: boolean
}

export type IPermissionDetail = {
  name: string
  key: UserPermissionEnum
  id: number
}

export enum QuizKindEnum {
  Test,
  Random,
  Maze,
}

export enum QuizStatus {
  Draft,
  Published,
}

export enum QuizEmbed {
  News,
  Article,
}

export enum QuizQuestionOptionLayout {
  Row,
  Col,
}
